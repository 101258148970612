@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");
.App {
  /* text-align: center; */
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

































ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.ReactModal__Content {
  border: none !important;
  background: rgba(41, 41, 41, 0.8) !important;
  overflow: unset !important;
}

.ReactModalPortal {
  position: relative;
  z-index: 10;
}

.MuiSkeleton-root {
  background-color: rgba(150, 150, 150, 0.3) !important;
}

.highcharts-label>text {
  fill: #1fc7d4 !important;
}

#WEB3_CONNECT_MODAL_ID {
  position: relative;
  z-index: 1000;
}

.MuiDialog-container>div {
  background-color: transparent !important;
  max-width: unset;
  margin: 0px !important;
}

.MuiDialog-paper {
  overflow: hidden !important;
}

.react-multi-carousel-list {
  position: unset !important;
}

.react-multi-carousel-item {
  display: flex !important;
  justify-content: center !important;
}

.MuiSlider-root {
  height: 10px !important;
  color: transparent !important;
}

.MuiSlider-rail {
  background-color: white !important;
  opacity: 1 !important;
}

.MuiSlider-thumb {
  background-color: #ffac1a !important;
  width: 30px !important;
  height: 30px !important;
}

@media screen and (max-width: 1250px) {
  .MuiSlider-thumb {
    width: 22px !important;
    height: 22px !important;
  }

  .MuiSlider-root {
    height: 7px !important;
  }
}

.MuiSlider-track {
  background-color: #ffac1a !important;
}

.MuiSvgIcon-root {
  font-size: 32px !important;
}

.MuiCheckbox-root {
  color: #f50057 !important;
}

.stats-label {
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  padding: 10px;
  letter-spacing: 0.4px;
  text-align: left;
  z-index: 1;
  background: linear-gradient(90deg, #0b0b0f 0%, rgba(10, 13, 28, 1) 100%);
  cursor: pointer;
}

.stats-label-color {
  width: 4px;
  height: 100%;
  margin-right: 15px;
}